import * as React from 'react'
import Layout from '../../../../../layout'
import ChooseAvatarPage from '../../../../../pagesComponents/groups/choose-avatar'

const ChooseAvatar = ({ id, teamId }) => {
	return (
		<Layout title="Choose Team Avatar">
			<ChooseAvatarPage id={id} teamId={teamId} />
		</Layout>
	)
}

export default ChooseAvatar
